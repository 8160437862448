import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/buch',
      name: 'buch',
      component: () => import('./views/Buch.vue')
    },
    {
      path: '/quitt',
      name: 'quitt',
      component: () => import('./views/Quitt.vue')
    },
    {
      path: '/buch-edit/:id/:bankKontoUmsatzId?',
      name: 'buch-edit',
      component: () => import('./views/BuchEdit.vue'),
      meta: { hideMenu: true }
    },
    {
      path: '/buch-export',
      name: 'buch-export',
      component: () => import('./views/BuchExport.vue')
    },
    {
      path: '/konti-export',
      name: 'konti-export',
      component: () => import('./views/KontiExport.vue')
    },
    {
      path: '/konto-buch/:id',
      name: 'konto-buch',
      component: () => import('./views/KontoBuch.vue')
    },
    {
      path: '/aktion',
      name: 'aktion',
      component: () => import('./views/Aktion.vue')
    },
    {
      path: '/aktion-spende/:id',
      name: 'aktion-spende',
      component: () => import('./views/AktionSpende.vue')
    },
    {
      path: '/sachkonto',
      name: 'sachkonto',
      component: () => import('./views/Sachkonto.vue')
    },
    {
      path: '/bankkonto',
      name: 'bankkonto',
      component: () => import('./views/Bankkonto.vue')
    },
    {
      path: '/bankumsatz',
      name: 'bankumsatz',
      component: () => import('./views/Bankumsatz.vue')
    },
    {
      path: '/bankauftrag',
      name: 'bankauftrag',
      component: () => import('./views/Bankauftrag.vue')
    },
    {
      path: '/bankauftrag-edit/:id',
      name: 'bankauftrag-edit',
      component: () => import('./views/BankauftragEdit.vue'),
      meta: { hideMenu: true }
    },
    {
      path: '/projekt',
      name: 'projekt',
      component: () => import('./views/Projekt.vue')
    },
    {
      path: '/lieferant',
      name: 'lieferant',
      component: () => import('./views/Lieferant.vue')
    },
    {
      path: '/konto',
      name: 'konto',
      component: () => import('./views/Konto.vue')
    },
    {
      path: '/kontakt',
      name: 'kontakt',
      component: () => import('./views/Kontakt.vue')
    },
    {
      path: '/kontakt-import',
      name: 'kontakt-import',
      component: () => import('./views/KontaktImport.vue')
    },
    {
      path: '/verlag-ausgabe',
      name: 'verlag-ausgabe',
      component: () => import('./views/VerlagAusgabe.vue')
    },
    {
      path: '/verlag-export',
      name: 'verlag-export',
      component: () => import('./views/VerlagExport.vue')
    },
    {
      path: '/kontakt-verlag',
      name: 'kontakt-verlag',
      component: () => import('./views/KontaktVerlag.vue')
    },
    {
      path: '/kontakt-edit/:id',
      name: 'kontakt-edit',
      component: () => import('./views/KontaktEdit.vue'),
      meta: { hideMenu: true }
    },
    {
      path: '/mieter',
      name: 'mieter',
      component: () => import('./views/Mieter.vue')
    },
    {
      path: '/mieter-edit/:id',
      name: 'mieter-edit',
      component: () => import('./views/MieterEdit.vue'),
      meta: { hideMenu: true }
    },
    {
      path: '/wohnung',
      name: 'wohnung',
      component: () => import('./views/Wohnung.vue')
    },
    {
      path: '/kontakt-spende/:id',
      name: 'kontakt-spende',
      component: () => import('./views/KontaktSpende.vue')
    },
    {
      path: '/kostenstelle',
      name: 'kostenstelle',
      component: () => import('./views/Kostenstelle.vue')
    },
    {
      path: '/user',
      beforeEnter() { location.href = this.$store.state.conf.authUserUrl }
    }
  ]
})
